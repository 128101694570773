<template>
    <div class="modal fade" id="settingsModal" tabindex="-1" aria-labelledby="settingsModalLabel" aria-hidden="true">
        <div class="modal-dialog modal-lg">
            <div class="modal-content">
                <div class="modal-body modal-settings_body">
                    <div class="modal-sideBar">
                        <button type="button" class="btn-close-modal-mobile" data-bs-dismiss="modal" aria-label="Close">
                            <font-awesome-icon :icon="['fal', 'xmark-large']" />
                        </button>
                        <div>
                            <div v-for="item in menuItems" :key="item.id" :class="['settings-item', { active: activeContent === item.id }]" @click.stop="showContent(item.id)">
                                <div class="settings-item_icon">
                                    <font-awesome-icon :icon="item.icon" />
                                </div>
                                <div class="settings-item_text">{{ item.text }}</div>
                            </div>
                        </div>
                    </div>
                    <div class="modal-mainContent">
                        <button type="button" class="btn-close-modal" data-bs-dismiss="modal" aria-label="Close">
                            <font-awesome-icon :icon="['fal', 'xmark-large']" />
                        </button>
                        <div :class="{ hidden: activeContent !== 'profile_data' }">
                            <ProfileData :updateUser="user" />
                        </div>
                        <div :class="{ hidden: activeContent !== 'security' }">
                            <SecuritySettings :updateUser="user" />
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import ProfileData from "@/components/Profile/ProfileData.vue"
import SecuritySettings from "@/components/Profile/SecuritySettings.vue"


export default {
    name: 'ProfileSettingsModal',
    components: {
        ProfileData,
        SecuritySettings
    },
    props: {
        user: {
            type: Object,
            required: true
        }
    },
    data() {
        return {
            activeContent: 'profile_data',
            menuItems: [
                { id: 'profile_data', icon: ['fal', 'user'], text: 'datos del perfil' },
                { id: 'security', icon: ['fal', 'lock'], text: 'seguridad' },
            ]
        };
    },
    methods: {
        showContent(content) {
            this.activeContent = content;
        },
    }

};
</script>

<style scoped>
.profile-settings-btn {
    background: var(--sirius-black);
    text-transform: uppercase;
    color: white;
    font-weight: 400;
    font-size: 14px;
    width: 100%;
}

.btn-icon {
    margin-right: 10px;
}

.modal-settings_body {
    background: white;
    display: flex;
    padding: 0;
    border-radius: 20px;
    border: 1px solid #000;
}

.modal-sideBar {
    border-right: 1px solid #D9D9D9;
    padding: 40px 0;
    min-width: 250px;
    display: flex;
    justify-content: space-between;
    flex-direction: column;
}

.settings-item {
    color: black;
    display: flex;
    align-items: center;
    position: relative;
    padding: 15px 40px;
    cursor: pointer;
}

.settings-item.active {
    background: #14329B;
    color: #fff;
    font-weight: 500;
}

.settings-item_icon {
    position: absolute;
    left: 40px;
}

.settings-item_text {
    padding-left: 30px;
    text-transform: uppercase;
    font-size: 12px;
}



.modal-mainContent {
    padding: 40px;
    position: relative;
    flex-grow: 1;
}

.btn-close-modal {
    background: transparent;
    position: absolute;
    top: 10px;
    right: 10px;
    border: 0;
    box-shadow: none;
    font-size: 20px;
    color: #101010;
    display: block;
}

.btn-close-modal-mobile {
    background: transparent;
    position: absolute;
    top: 20px;
    right: 20px;
    border: 0;
    box-shadow: none;
    font-size: 20px;
    color: #101010;
    display: none;
}

.hidden {
    display: none;
}

@media screen and (max-width: 768px) {
    .modal-settings_body {
        flex-direction: column;
    }

    .modal-sideBar {
        border-right: 0;
        padding-top: 60px;
    }


    .btn-close-modal {
        display: none;
    }

    .btn-close-modal-mobile {
        display: block;
    }
}
</style>