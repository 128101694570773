<template>
    <div @click.stop>
        <label v-if="!isDelegateUser" class="profileModal-picture mb-4">
            <div class="profile-img-container">
                <img :src="localUpdateUser.profile.profile_photo || '/assets/images/bg/profileImg-default.png'" />
            </div>
            <div>
                <input type="file" id="fileInput" ref="fileInput" @change="uploadImage" />
                <label for="fileInput" class="custom-file-label">Elegir imagen</label>
            </div>
        </label>
        <div>
            <input type="text" class="form-inputs mb-3 custom-input" placeholder="Nombre" v-model="localUpdateUser.profile.first_name">
        </div>
        <div>
            <input type="text" class="form-inputs mb-3 custom-input" placeholder="Apellidos" v-model="localUpdateUser.profile.last_name">
        </div>
        <div>
            <input type="email" class="form-inputs mb-3 custom-input" placeholder="Dirección de email" :value="localUpdateUser.email" disabled>
        </div>
        <button @click.stop="updateUserData" id="btnSubmitUpdateUser" class="btn btn-primary mt-3" :disabled="isUpdating">
            <span v-if="isUpdating">Guardando...</span>
            <span v-else>Guardar</span>
        </button>
    </div>
</template>

<script>
import UserService from '@/services/user.service.js';

export default {
    props: {
        updateUser: Object,
    },
    data() {
        return {
            isUpdating: false,
            localUpdateUser: {}
        };
    },
    watch: {
        updateUser: {
            immediate: true,
            handler(newValue) {
                this.localUpdateUser = JSON.parse(JSON.stringify(newValue));
                if (newValue.profile && newValue.profile.interests) {
                    this.selectedCategories = newValue.profile.interests.map(interest => interest.UUID);
                }
            }
        }
    },

    computed: {
        isDelegateUser() {
            return this.$store.state.auth?.user?.data?.profile?.profile_type === "delegate";
        },
        isOfficeUser() {
            return this.$store.state.auth?.user?.data?.profile?.profile_type === "office-user";
        },
    },
    methods: {
        uploadImage(e) {
            const image = e.target.files[0];
            const reader = new FileReader();
            reader.readAsDataURL(image);
            reader.onload = e => {
                this.localUpdateUser.profile.profile_photo = e.target.result;
            };
        },
        async updateUserData() {
            this.isUpdating = true;

            const formData = new FormData();

            // Añadir la imagen si hay una seleccionada
            if (!this.isDelegateUser && this.$refs.fileInput.files.length > 0) {
                const image = this.$refs.fileInput.files[0];
                formData.append('profile_photo', image);
            }

            // Añadir los otros datos del usuario
            formData.append('first_name', this.localUpdateUser.profile.first_name);
            formData.append('last_name', this.localUpdateUser.profile.last_name);

            try {
                const response = await UserService.update_user(this.localUpdateUser.UUID, formData);
                console.log('User updated successfully:', response);
                if (response.status === 200) {
                    this.$swal.fire({
                        icon: 'success',
                        title: 'Actualizado',
                        text: 'Los datos del usuario se han actualizado correctamente.',
                        confirmButtonColor: '#00BCFF'
                    }).then(() => {
                        window.location.reload(); // Recargar la página después de cerrar la alerta
                    });
                } else {
                    this.$swal.fire({
                        icon: 'error',
                        title: 'Error',
                        text: 'No se pudo actualizar los datos del usuario. Por favor, inténtelo de nuevo.',
                        confirmButtonColor: '#00BCFF'
                    });
                }
            } catch (error) {
                console.error('Error updating user:', error);
                this.$swal.fire({
                    icon: 'error',
                    title: 'Error',
                    text: 'Ocurrió un error al actualizar los datos del usuario. Por favor, inténtelo de nuevo más tarde.',
                    confirmButtonColor: '#00BCFF'
                });
            } finally {
                this.isUpdating = false;
            }
        },
    },
    created() {
    }
};
</script>

<style scoped>
.profile-img-container {
    width: 140px;
    height: 140px;
    border-radius: 10px;
    background-color: #ECECEC;
    padding: 10px;
}

.profile-img-container img {
    object-fit: cover;
    object-position: center;
    border-radius: 10px;
    width: 100%;
    max-width: 100%;
}

.profileModal-picture {
    display: flex;
    align-items: center;
    gap: 30px;
}


input[type="file"] {
    display: none;
}

.custom-file-label {
    display: inline-block;
    padding: 5px 30px;
    background-color: transparent;
    color: #101010;
    border-radius: 50px;
    cursor: pointer;
    text-transform: uppercase;
    font-size: 10px;
    font-weight: 400;
    text-align: center;
    transition: background-color 0.3s;
    border: 1px solid #14329B;
}

.custom-file-label:hover {
    background-color: #14329B;
    color: #fff;
}

.custom-input {
    background-color: #ECECEC;
    color: #000;
    border-radius: 10px;
    border-color: #ECECEC;
    width: 100%;
    box-shadow: none;
    padding: 5px 20px;
    border: 0;
    height: 40px;
}


#btnSubmitUpdateUser.btn-primary {
    background-color: #53A8F4;
    border-radius: 10px;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    color: #0E1834;
}

#btnSubmitUpdateUser.btn-primary:hover,
#btnSubmitUpdateUser.btn-primary:focus {
    background-color: #14329B;
    color: #fff;
}
</style>
