// app.js
export default [
	{
		path: "/home",
		name: "HomeView",
		component: () => import("@/views/Pages/Home.vue"),
		meta: { requiresAuth: true },
	},
	{
		path: "/profile",
		name: "ProfileView",
		component: () => import("@/views/Pages/Profile.vue"),
		meta: { requiresAuth: true, requiresDelegateUser: true },
	},
	{
		path: "/season",
		name: "SeasonView",
		component: () => import("@/views/Pages/Season.vue"),
		meta: { requiresAuth: true },
	},
	{
		path: "/ranking",
		name: "RankingView",
		component: () => import("@/views/Pages/Ranking.vue"),
		meta: { requiresAuth: true, requiresOfficeUser: true },
	},
	{
		path: "/calendar",
		name: "CalendarView",
		component: () => import("@/views/Pages/Calendar.vue"),
		meta: { requiresAuth: true },
	},
	{
		path: "/tests/single",
		name: "SingleTestView",
		component: () => import("@/views/Pages/Test/SingleTest.vue"),
		meta: { requiresAuth: true },
	},
	{
		path: "/tests/longanswer",
		name: "LongAnswerTestView",
		component: () => import("@/views/Pages/Test/LongAnswerTest.vue"),
		meta: { requiresAuth: true },
	},
	{
		path: "/tests/review/longanswer",
		name: "LongAnswerTestReviewView",
		component: () => import("@/views/Pages/Test/Review/LongAnswerTestReview.vue"),
		meta: { requiresAuth: true },
	},
	{
		path: "/tests/review/single",
		name: "SingleTestReviewView",
		component: () => import("@/views/Pages/Test/Review/SingleTestReview.vue"),
		meta: { requiresAuth: true },
	},
	{
		path: "/tests/review/pasapalabra",
		name: "PasapalabraReviewView",
		component: () => import("@/views/Pages/Test/Review/PasapalabraReview.vue"),
		meta: { requiresAuth: true },
	},
	{
		path: "/tests/pasapalabra",
		name: "PasapalabraTestView",
		component: () => import("@/views/Pages/Test/PasapalabraTest.vue"),
		meta: { requiresAuth: true },
	},
	{
		path: "/students/:student_id",
		name: "StudentView",
		component: () => import("@/views/Pages/Student.vue"),
		meta: { requiresAuth: true, requiresOfficeUser: true },
	},
	{
		path: "/teams/:team_id",
		name: "TeamView",
		component: () => import("@/views/Pages/Team.vue"),
		meta: { requiresAuth: true, requiresOfficeUser: true },
	},
	{
		path: "/grading/records",
		name: "GradingRecordsView",
		component: () => import("@/views/Pages/GradingRecords.vue"),
		meta: { requiresAuth: true, requiresOfficeUser: true },
	},
];
