<template>
    <svg width="27" height="27" viewBox="0 0 27 27" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M11.243 17.314L7 13.07L8.414 11.656L11.243 14.484L16.899 8.82703L18.314 10.242L11.243 17.314Z" fill="#53A8F4" />
        <path fill-rule="evenodd" clip-rule="evenodd"
            d="M2 13C2 6.925 6.925 2 13 2C19.075 2 24 6.925 24 13C24 19.075 19.075 24 13 24C6.925 24 2 19.075 2 13ZM13 22C11.8181 22 10.6478 21.7672 9.55585 21.3149C8.46392 20.8626 7.47177 20.1997 6.63604 19.364C5.80031 18.5282 5.13738 17.5361 4.68508 16.4442C4.23279 15.3522 4 14.1819 4 13C4 11.8181 4.23279 10.6478 4.68508 9.55585C5.13738 8.46392 5.80031 7.47177 6.63604 6.63604C7.47177 5.80031 8.46392 5.13738 9.55585 4.68508C10.6478 4.23279 11.8181 4 13 4C15.3869 4 17.6761 4.94821 19.364 6.63604C21.0518 8.32387 22 10.6131 22 13C22 15.3869 21.0518 17.6761 19.364 19.364C17.6761 21.0518 15.3869 22 13 22Z"
            fill="#53A8F4" />
    </svg>

</template>

<script>
export default {
    name: 'CheckIcon'
};
</script>
