import axios from "axios";

const API_URL = process.env.NODE_ENV === "production" ? "https://apitheraceison.bayer.es/api/v1" : "http://127.0.0.1:8000/api/v1";

export const WS_URL = process.env.NODE_ENV === "production" ? "wss://apitheraceison.bayer.es/api/v1" : "ws://127.0.0.1:8000/api/v1";

const instance = axios.create({
	baseURL: API_URL,
	headers: {
		"Content-Type": "application/json",
	},
});

export default instance;
