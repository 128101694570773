import { createWebHistory, createRouter } from "vue-router";
import store from "@/store";
import AuthService from "@/services/auth.service";

// Importa las rutas
import authRoutes from "./routes/auth";
import appRoutes from "./routes/app";

const routes = [
	{
		path: "/",
		name: "RootView",
		component: () => import("@/views/Pages/Home.vue"),
		meta: { requiresAuth: true },
	},
];

routes.push(...authRoutes);
routes.push(...appRoutes);

const router = createRouter({
	history: createWebHistory(),
	routes,
	scrollBehavior(to, from, savedPosition) {
		if (savedPosition) {
			return savedPosition;
		} else {
			return { top: 0 };
		}
	},
});

// Add a global navigation guard to check authentication
router.beforeEach(async (to, from, next) => {
	// Verificar si la ruta requiere autenticación
	if (to.meta.requiresAuth) {
		// Verificar si el usuario está autenticado
		if (AuthService.isLoggedIn()) {
			// Usuario autenticado, carga el usuario actual si no está en el estado de Vuex
			if (!store.state.auth.user) {
				try {
					await store.dispatch("auth/loadUser");
					next();
				} catch (error) {
					console.error("Error loading current user:", error);
					next({ name: "LoginView" });
				}
			} else {
				// Si la ruta requiere un DelegateUser, verificamos el tipo de usuario
				if (to.meta.requiresOfficeUser) {
					const isOfficeUser = store.state.auth.user.data.profile.profile_type === "office-user";
					if (!isOfficeUser) {
						// Si no es un DelegateUser, redirigir al home u otra página
						return next({ name: "HomeView" });
					}
				}

				// Si la ruta requiere un DelegateUser, verificamos el tipo de usuario
				if (to.meta.requiresDelegateUser) {
					const isDelegateUser = store.state.auth.user.data.profile.profile_type === "delegate";
					if (!isDelegateUser) {
						// Si no es un DelegateUser, redirigir al home u otra página
						return next({ name: "HomeView" });
					}
				}

				// Si pasa todas las verificaciones, permite el acceso
				next();
			}
		} else {
			// Usuario no autenticado, redirige al inicio de sesión
			next({ name: "LoginView" });
		}
	} else {
		// La ruta no requiere autenticación, permite el acceso
		next();
	}
});

export default router;
