<template>
    <div class="sidebar-container">
        <div v-if="isMobile" class="hamburger-menu-container">
            <button @click.stop="toggleSidebar" class="hamburger-menu">
                <font-awesome-icon :icon="['far', 'bars']" />
                <span class="ms-2">Menú</span>
            </button>
            <a href="/"><img src="/assets/images/logos/nubeqarace-white.png" style="width: 100%; max-width: 120px; max-height: 30px" /></a>
        </div>
        <div :class="['sidebar', { 'sidebar-open': sidebarOpen }]" id="sidebar" @click.stop>
            <div class="logo text-center mb-3">
                <a href="/"><img src="/assets/images/logos/logo-nubeqa.png" style="width: 100%; max-width: 120px" /></a>

                <hr class="separator mt-5">
            </div>
            <div class="nav-menu">
                <div class="d-flex flex-column justify-content-between">
                    <ul class="list">
                        <SidebarMenuItem @click="closeSidebar" to="/home" icon="HomeIcon" text="Home" />
                        <!--<SidebarMenuItem @click="closeSidebar" to="/season" icon="Season2Icon" text="Temporada 2" />-->
                        <SidebarMenuItem @click="closeSidebar" to="/season" icon="Season1Icon" text="Temporada 1" />
                        <SidebarMenuItem @click="closeSidebar" to="/calendar" icon="CalendarIcon" text="Agenda Anual" />
                        <SidebarMenuItem v-if="isOfficeUser" @click="closeSidebar" to="/ranking" icon="RankingIcon" text="Ranking" />
                        <SidebarMenuItem v-if="isOfficeUser" @click="closeSidebar" to="/grading/records" icon="CheckIcon" text="Calificación" />
                    </ul>
                    <div class="contact-info">Si tienes alguna duda, puedes contactar a jorge.alberola@bayer.com</div>
                </div>
                <div class="profile-box">
                    <ProfileDropdown />
                </div>
                <div class="text-center">
                    <img src="/assets/images/logos/nubeqarace-white.png" style="width: 100%; max-width: 180px" />
                </div>

            </div>
        </div>
    </div>
</template>

<script>
import SidebarMenuItem from '@/components/Sidebar/SidebarMenuItem.vue';
import ProfileDropdown from '@/components/Sidebar/ProfileDropdown.vue';

export default {
    name: "SidebarMenu",
    components: {
        SidebarMenuItem,
        ProfileDropdown
    },
    props: {
        isMobile: {
            type: Boolean,
            required: true
        },
        sidebarOpen: {
            type: Boolean,
            required: true
        },
        toggleSidebar: {
            type: Function,
            required: true
        },
        closeSidebar: {
            type: Function,
            required: true
        }
    },
    computed: {
        isDelegateUser() {
            return this.$store.state.auth?.user?.data?.profile?.profile_type === "delegate";
        },
        isOfficeUser() {
            return this.$store.state.auth?.user?.data?.profile?.profile_type === "office-user";
        },
    },

    mounted() {
        // Ensure focus is managed correctly
        document.addEventListener('keydown', this.handleFocus);
    },
    beforeUnmount() {
        document.removeEventListener('keydown', this.handleFocus);
    },
    methods: {
        handleFocus(event) {
            if (event.key === "Tab" || event.key === "Escape") {
                this.closeSidebar();
            }
        },
    }
};
</script>

<style scoped>
.sidebar {
    background: linear-gradient(0deg, rgba(0, 0, 0, 0.50) 0%, rgba(0, 0, 0, 0.50) 100%), linear-gradient(180deg, #2D4385 0%, #061646 100%);
}

/* Additional Styles */


#sidebar .nav-menu {
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
}

#sidebar .nav-menu ul {
    padding-left: 20px;
    padding-right: 20px;
}


.separator {
    border-color: #fff;
    border-width: 1px;
    opacity: 0.5;
}

.profile-item .menu-link .menu-icon {
    width: 30px;
}

.signout-text {
    text-transform: uppercase;
    text-decoration: underline;
    padding-left: 40px;
}

hr {
    margin: 0;
}

.profile-box {
    padding-top: 30px;
    border-top: 1px solid rgba(255, 255, 255, 0.50);
    padding-left: 20px;
    padding-right: 20px;
    margin-bottom: 30px;
}

.contact-info {
    color: #2153FF;
    font-size: 14px;
    padding: 20px;
    text-align: center;
}

@media screen and (max-width: 992px) {
    .hamburger-menu-container {
        width: 100%;
        background-color: #0F2054;
        position: absolute;
        left: 0;
        right: 0;
        height: 40px;
        z-index: 10;
        display: flex;
        align-items: center;
        padding-left: 20px;
        padding-right: 20px;
        justify-content: space-between;
    }
}
</style>
