<template>
    <div>
        <form @submit.prevent="updatePassword">
            <div class="mb-4 title">
                cambia o actualiza tu contraseña
            </div>
            <div>
                <label class="mb-2">contraseña actual</label>
                <div class="password-input-container">
                    <input :type="showCurrentPassword ? 'text' : 'password'" class="form-inputs mb-3 custom-input" placeholder="Contraseña actual" v-model="currentPassword"
                        autocomplete="current-password">
                    <span @click="toggleShowCurrentPassword" class="password-toggle-icon">
                        <font-awesome-icon :icon="showCurrentPassword ? 'eye-slash' : 'eye'" style="color: var(--sirius-fill);" />
                    </span>
                </div>
            </div>
            <div>
                <label class="mb-2">contraseña nueva</label>
                <div class="password-input-container">
                    <input :type="showNewPassword ? 'text' : 'password'" class="form-inputs mb-3 custom-input" placeholder="Contraseña nueva" v-model="newPassword" autocomplete="new-password">
                    <span @click="toggleShowNewPassword" class="password-toggle-icon">
                        <font-awesome-icon :icon="showNewPassword ? 'eye-slash' : 'eye'" style="color: var(--sirius-fill);" />
                    </span>
                </div>
            </div>
            <div>
                <label class="mb-2">repite la contraseña nueva</label>
                <div class="password-input-container">
                    <input :type="showRepeatNewPassword ? 'text' : 'password'" class="form-inputs mb-3 custom-input" placeholder="Contraseña nueva" v-model="repeatNewPassword"
                        autocomplete="new-password">
                    <span @click="toggleShowRepeatNewPassword" class="password-toggle-icon">
                        <font-awesome-icon :icon="showRepeatNewPassword ? 'eye-slash' : 'eye'" style="color: var(--sirius-fill);" />
                    </span>
                </div>
            </div>

            <div class="requirements">
                <div :class="{ 'text-success requirement-text': passwordStrength === 'strong', 'text-danger requirement-text': passwordStrength === 'weak' }">
                    <font-awesome-icon :icon="passwordStrength === 'strong' ? ['fal', 'check'] : ['fal', 'xmark']" />
                    Seguridad de la contraseña: {{ passwordStrength === 'strong' ? 'Buena' : 'Mala' }}
                </div>
                <div :class="{ 'text-success requirement-text': passwordLength, 'text-danger requirement-text': !passwordLength }">
                    <font-awesome-icon :icon="passwordLength ? ['fal', 'check'] : ['fal', 'xmark']" />
                    Al menos 8 caracteres
                </div>
                <div :class="{ 'text-success requirement-text': passwordNumberOrSymbol, 'text-danger requirement-text': !passwordNumberOrSymbol }">
                    <font-awesome-icon :icon="passwordNumberOrSymbol ? ['fal', 'check'] : ['fal', 'xmark']" />
                    Contiene un número o símbolo
                </div>
                <div :class="{ 'text-success requirement-text': !passwordContainsEmailPart, 'text-danger requirement-text': passwordContainsEmailPart }">
                    <font-awesome-icon :icon="!passwordContainsEmailPart ? ['fal', 'check'] : ['fal', 'xmark']" />
                    No puede incluir parte de tu email
                </div>
            </div>

            <button id="submitBtn" type="submit" class="btn btn-primary mt-3" :disabled="isSubmitting">
                <span v-if="!isSubmitting">Cambiar contraseña</span>
                <span v-else>
                    <font-awesome-icon icon="spinner" spin /> Guardando...
                </span>
            </button>
        </form>
    </div>
</template>

<script>
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome';
import UserService from '@/services/user.service.js';

export default {
    components: {
        FontAwesomeIcon
    },
    props: {
        updateUser: {
            type: Object,
            required: true
        }
    },
    data() {
        return {
            currentPassword: '',
            newPassword: '',
            repeatNewPassword: '',
            showCurrentPassword: false,
            showNewPassword: false,
            showRepeatNewPassword: false,
            isSubmitting: false,
        };
    },
    computed: {
        passwordStrength() {
            if (this.newPassword.length >= 8 && /\d|[^A-Za-z0-9]/.test(this.newPassword) && !this.passwordContainsEmailPart) {
                return 'strong';
            } else {
                return 'weak';
            }
        },
        passwordLength() {
            return this.newPassword.length >= 8;
        },
        passwordNumberOrSymbol() {
            return /\d|[^A-Za-z0-9]/.test(this.newPassword);
        },
        passwordContainsEmailPart() {
            if (!this.updateUser.email) return false;
            const emailPart = this.updateUser.email.split('@')[0];
            return this.newPassword.includes(emailPart);
        }
    },
    methods: {
        toggleShowCurrentPassword() {
            this.showCurrentPassword = !this.showCurrentPassword;
        },
        toggleShowNewPassword() {
            this.showNewPassword = !this.showNewPassword;
        },
        toggleShowRepeatNewPassword() {
            this.showRepeatNewPassword = !this.showRepeatNewPassword;
        },
        async updatePassword() {
            if (this.passwordStrength === 'strong' && this.newPassword === this.repeatNewPassword) {
                this.isSubmitting = true; // Activar el estado de envío

                try {
                    const payload = {
                        current_password: this.currentPassword,
                        password: this.newPassword
                    };
                    const response = await UserService.update_user_password(this.updateUser.UUID, payload);
                    if (response.status === 200) {
                        this.$swal.fire({
                            icon: 'success',
                            title: 'Actualizado',
                            text: 'La contraseña se ha actualizado correctamente.',
                            confirmButtonColor: '#00BCFF'
                        });
                    } else {
                        this.$swal.fire({
                            icon: 'error',
                            title: 'Error',
                            text: 'No se pudo actualizar la contraseña. Por favor, inténtelo de nuevo.',
                            confirmButtonColor: '#00BCFF'
                        });
                    }
                } catch (error) {
                    this.$swal.fire({
                        icon: 'error',
                        title: 'Error',
                        text: 'Ocurrió un error al actualizar la contraseña. Por favor, inténtelo de nuevo más tarde.',
                        confirmButtonColor: '#00BCFF'
                    });
                } finally {
                    this.isSubmitting = false; // Desactivar el estado de envío después de la solicitud
                }
            } else {
                this.$swal.fire({
                    icon: 'error',
                    title: 'Error',
                    text: 'Por favor, asegúrese de que la contraseña cumpla con los requisitos y que ambas contraseñas coincidan.',
                    confirmButtonColor: '#00BCFF'
                });
            }
        }
    }

};
</script>

<style scoped>
.title {
    color: #14329B;
    font-size: 14px;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
    text-transform: uppercase;
}

label {
    color: #000;
    font-family: Gotham;
    font-size: 12px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    text-transform: uppercase;
}

.custom-input {
    background-color: #ECECEC;
    color: #000;
    border-radius: 10px;
    border-color: #ECECEC;
    width: 100%;
    box-shadow: none;
    padding: 5px 20px;
    border: 0;
    height: 40px;
}

.password-input-container {
    position: relative;
}

.password-toggle-icon {
    position: absolute;
    top: 25%;
    right: 10px;
    transform: translateY(-24%);
    cursor: pointer;
    color: #fff;
}

.requirements {
    margin-top: 10px;
    margin-bottom: 30px;
}

.text-success {
    color: var(--sirius-green) !important;
}

.text-danger {
    color: var(--sirius-light-orange) !important;
}

.requirement-text {
    font-size: 12px;
}

.requirement-text svg {
    margin-right: 10px;
}

#submitBtn.btn-primary {
    background-color: #53A8F4;
    border-radius: 10px;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    color: #0E1834;
}

#submitBtn.btn-primary:hover,
#submitBtn.btn-primary:focus {
    background-color: #14329B;
    color: #fff;
}
</style>
