import { createApp } from "vue";
import App from "./App.vue";
import store from "@/store/index";
import router from "@/router/index";
import SwalPlugin from "@/plugins/sweetalert2";
import BootstrapPlugin from "@/plugins/bootstrap";

// Import Styles & Scripts files
require("@/index");

import setupInterceptors from "@/services/setupInterceptors";
setupInterceptors(store);

/* import the fontawesome core */
import { library } from "@fortawesome/fontawesome-svg-core";

/* import font awesome icon component */
import { FontAwesomeIcon } from "@fortawesome/vue-fontawesome";

/* import specific icons */
import { all } from "@awesome.me/kit-b01bf36b9b/icons";

/* add icons to the library */
library.add(...all);

import closeModalsMixin from "@/mixins/closeModalsMixin";

import MazDropzone from "maz-ui/components/MazDropzone";

const app = createApp(App);
app.use(router);
app.use(store);
app.use(SwalPlugin);
app.use(BootstrapPlugin);
app.component("font-awesome-icon", FontAwesomeIcon);
app.component("MazDropzone", MazDropzone);
app.mixin(closeModalsMixin);
app.mount("#app");
